<template>
  <div>
    <multiple-column-form title="Informasi Mahasiswa" :fields="forms" :api="apiBiodata" />

    <b-card title="Grafik Nilai">
      <line-chart :api="api_gpa" />

      <c-table
        :title="title"
        :items="getItems"
        :columns="columns"
        :totalRows="totalRows"
        :perPage="perPage"
        :currentPage="currentPage"
        :api="api"
        @changeCurrentPage="changeCurrentPage"
        @changePerPage="changePerPage"
      ></c-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
} from "bootstrap-vue";
import LineChart from "@/components/LineChartTranscript.vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import MultipleColumnForm from "@/components/MultipleColumnForm.vue";
import CTable from "@/components/CTable";
import table from "@/libs/table";

export default {
  components: {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    MultipleColumnForm,
    LineChart,
    CTable,
  },
  data() {
    return {
      api_gpa: "student-grades/student-gpa",
      forms: [
        {
          type: "input",
          label: "NIM",
          label_for: "nim",
          id: "nim",
          placeholder: null,
          readonly: true,
        },
        {
          type: "input",
          label: "Fakultas",
          label_for: "faculty",
          id: "faculty",
          placeholder: null,
          readonly: true,
        },
        {
          type: "input",
          label: "Nama Mahasiswa",
          label_for: "name",
          id: "name",
          placeholder: null,
          readonly: true,
        },
        {
          type: "input",
          label: "Program Studi",
          label_for: "prodi",
          id: "prodi",
          placeholder: null,
          readonly: true,
        },
        {
          type: "input",
          label: "Semester",
          label_for: "semester",
          id: "semester",
          placeholder: null,
          readonly: true,
        },
      ],
      apiBiodata: "student-grades/student-biodata",
      title: "Nilai Mahasiswa",
      api: "student-grades/student",
      items: [],
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      columns: [
        { key: "blockName", label: "Nama Blok", sortable: true },
        { key: "hm", label: "Huruf Mutu", sortable: true },
        { key: "attendance", label: "Persentase Kehadiran", sortable: true },
      ],
    };
  },
  async mounted() {},
  methods: {
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
  },
};
</script>
