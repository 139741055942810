<template>
  <b-card :title="title">
    <b-card-body>
      <b-form>
        <b-row>
          <b-col md="6" v-for="form in forms" :key="form.id">
            <b-form-group :label="form.label" :label-for="form.label_for" label-cols-md="4">
              <b-form-input
                v-if="form.type === 'input'"
                :id="form.id"
                :placeholder="form.placeholder"
                :readonly="form.readonly"
                :value="form.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BCardBody,
  VBPopover,
  VBModal,
  BModal,
  BAlert,
  BFormFile,
  BRow,
  BCol,
  BForm,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: {
    BCard,
    BForm,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCardBody,
    VBPopover,
    VBModal,
    BModal,
    BAlert,
    BFormFile,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    values: {
      required: false,
      default: () => [],
    },
    api: {
      required: false,
      default: () => "",
    },
  },
  data() {
    return {
      model: {},
      forms: [],
      uri: "",
    };
  },
  async mounted() {
    this.uri = this.$props.api;
    this.model = await this.getData();
    this.mapping_value();
  },
  methods: {
    mapping_value() {
      const _ = this;
      for (const [key, value] of Object.entries(_.model)) {
        _.$props.fields.forEach((element) => {
          if (element.id === key) {
            element.value = value;
            _.forms = [..._.forms, element];
          }
        });
      }
    },
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(this.uri).then((res) => {
        data = res.data.data;
      });
      return data;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
