<template>
  <b-card>
    <b-row>
      <b-col md="4" class="border-right">
        <b-row>
          <b-col class="mb-5">
            <div class="mb-1 mb-sm-0">
              <h3>IPS</h3>
            </div>
            <div class="d-flex align-content-center mb-1 mb-sm-0">
              <h1 class="font-weight-bolder">{{ accumulation.ips }}</h1>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mb-1 mb-sm-0">
              <h3>IPK</h3>
            </div>
            <div class="d-flex align-content-center mb-1 mb-sm-0">
              <h1 class="font-weight-bolder">{{ accumulation.ipk }}</h1>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="8">
        <e-charts
          ref="line"
          autoresize
          :options="line"
          theme="theme-color"
          auto-resize
        />
        <h4 class="text-center">Grafik Indeks Prestasi Semester (IPS)</h4>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
} from "bootstrap-vue";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
  },
  props: {
    api: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      uri: this.$props.api,
      accumulation: {},
      line: {
        // Make gradient line here
        visualMap: [
          {
            show: true,
            type: "continuous",
            min: 0,
            max: 400,
          },
        ],
        grid: {
          width: "92%",
          left: "24px",
          top: "10px",
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            showGrid: false,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false, // Hide full Line
            },
            boundaryGap: false,
            data: [1, 2, 3, 4, 5, 6, 7, 8],
          },
        ],
        yAxis: {
          axisLine: {
            show: false, // Hide full Line
          },
          type: "value",
          splitLine: { show: false },
        },
        series: {
          type: "line",
          showSymbol: true,
          data: [],
          label: {
            show: true,
            position: "bottom",
            textStyle: {
              fontSize: 14,
            },
          },
        },
      },
    };
  },
  async mounted() {
    const _ = this;
    const data = await _.getData();
    _.line.xAxis.data = _.mapData(data, "semester");
    _.line.series.data = _.mapData(data, "ips");
    _.accumulation = data.accumulation;
  },
  methods: {
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(_.uri).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    mapData(data, type) {
      let consume = [];
      data.semesterGPA.forEach((element) => {
        if (type === "semester") {
          consume = [...consume, element.semester];
        } else if (type === "ips") {
          consume = [...consume, element.ips];
        }
      });
      return consume;
    },
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
